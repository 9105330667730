import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { Kfh_UmbracoButtonVideoType } from '../../../types/graphqlTypes';
import { Button } from '../../Button';
import { IconLink } from '../../IconLink';
import { Close } from '../../icon/icons/Close';
import { Play } from '../../icon/icons/Play';
import { Video as VideoPlayer } from '../../Video';
import { Container } from '../../Container';
import { IconButton } from '../../IconButton';
import { Portal } from '../../Portal';

interface LinkVideoProps extends Kfh_UmbracoButtonVideoType {
  arrowLink?: boolean;
}

const StyledOverlay = styled.div`
  ${({ theme: { layers } }) => css`
    background: rgba(17, 17, 17, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${layers.videoOverlay};
  `};
`;

const StyledVideoWrapper = styled.div`
  ${({ theme: { space } }) => css`
    position: relative;
    margin-top: ${space.xl};
  `};
`;

const StyledIconButton = styled(IconButton)`
  ${({ theme: { colors, space, media } }) => css`
    color: ${colors.white.default};
    position: absolute;
    right: 0;
    top: -${space.lg};

    &:hover {
      color: ${colors.brand.default};
    }

    @media (min-width: ${media.md}) {
      right: -${space.lg};
      top: -${space.lg};
    }
  `};
`;

const OVERLAY_ID = 'video-overlay';

export const Video: FC<LinkVideoProps> = ({ arrowLink, label, videoUrl, ...rest }) => {
  const [videoOverlayActive, setVideoOverlayActive] = useState(false);

  const handleBackgroundClick = (e) => {
    if (e.target.id === OVERLAY_ID) {
      setVideoOverlayActive(false);
    }
  };

  return (
    <>
      {arrowLink ? (
        <IconLink onClick={() => setVideoOverlayActive(true)} {...rest} label={label} iconRight={<Play />} />
      ) : (
        <Button onClick={() => setVideoOverlayActive(true)} {...rest} label={label} iconRight={<Play />} />
      )}

      {videoOverlayActive && (
        <Portal id="blade-portal">
          <StyledOverlay onClick={(e) => handleBackgroundClick(e)} id={OVERLAY_ID}>
            <Container>
              <StyledVideoWrapper>
                <StyledIconButton a11yTitle="close video" onClick={() => setVideoOverlayActive(false)}>
                  <Close size="medium" />
                </StyledIconButton>
                <VideoPlayer videoUrl={videoUrl} />
              </StyledVideoWrapper>
            </Container>
          </StyledOverlay>
        </Portal>
      )}
    </>
  );
};

export const LinkVideoQuery = graphql`
  fragment LinkVideo on KFH_UmbracoButtonVideoType {
    videoUrl
    label
  }
`;
