import React, { FC, Suspense, useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Input } from './Input';
import { Panel } from '../Panel';
import { Spacer } from '../Spacer';
import { FormSubmissionInput } from '../../types/graphqlTypes';
import { useCreate_Build_To_RentMutation } from '../../types/graphqlHelpers';
import { Textarea } from './Textarea';
import { Typography } from '../Typography';
import { TITLES } from '../../const';
import Blade, { BladeBody, BladeContainer, BladeFooter } from '../Blade';
import { useDataLayerPush } from '../../hooks/useDataLayerPush';
import { SmallPrint } from './SmallPrint';
import { isBrowser } from '../../utils/isBrowser';
import { Loader } from '../Loader';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { RecaptchaAction } from './RecaptchaAction';
import { TickCircle } from '../icon';
import { alertBadEmail, validateEmail } from '../../utils/emailBlackList';
import { Select } from './Select';

const PhoneNumberInput = React.lazy(() => import('./PhoneNumberInput'));

export interface BuildToRentFormProps {}

const StyledTitleWrap = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.md};
  `}
`;

const StyledLead = styled(Typography)`
  ${({ theme: { space } }) => css`
    max-width: 530px;
  `}
`;

export const BuildToRentForm: FC<BuildToRentFormProps> = ({ ...rest }) => {
  const { dataLayerPush } = useDataLayerPush();
  const [hasSent, setHasSent] = useState(false);
  const [CreateBuildToRentMutation, { data, loading }] = useCreate_Build_To_RentMutation();

  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const { register, handleSubmit, formState, control } = formMethods;
  const { errors, dirtyFields, touchedFields, isSubmitted } = formState;
  const { handleReCaptchaVerify, isHuman } = useRecaptcha({ formMethods, action: 'buildToRentForm' });

  useEffect(() => {
    function checkSuccess() {
      if (!loading && data?.form?.createFormSubmission?.success) {
        setHasSent(true);
      }
    }
    checkSuccess();
  }, [data, dataLayerPush, loading]);

  const onSubmit: SubmitHandler<FormSubmissionInput> = async (formData) => {
    alertBadEmail(formData.email);
    delete formData.captchaToken;
    const submitData = {
      type: 'BUILD_TO_RENT',
      pageUrl: isBrowser ? window.location.href : '',
      fromName: `${formData.firstName} ${formData.lastName}`,
      fromEmail: formData.email,
      fields: [
        { name: 'Title', value: formData.title },
        { name: 'First name', value: formData.firstName },
        { name: 'Last name', value: formData.lastName },
        { name: 'Email', value: formData.email },
        { name: 'Phone', value: formData.telephone },
        { name: 'Address line 1', value: formData.addressLine1 },
        { name: 'Address line 2', value: formData.addressLine2 },
        { name: 'Town', value: formData.town },
        { name: 'Postcode', value: formData.postcode },
        { name: 'Message', value: formData.message },
      ],
    };

    dataLayerPush({
      event: 'formSubmit',
      formName: 'buildToRentForm',
      formValidation: 'success',
      formError: undefined,
    });

    await CreateBuildToRentMutation({
      variables: { formSubmission: submitData },
    });
  };

  return (
    <Blade id="buildToRent" customBlade>
      <FormProvider {...formMethods}>
        {(!isSubmitted || !hasSent) && (
          <BladeContainer>
            <BladeBody>
              <Spacer>
                <Typography variant="displaySmall">Get in touch with our expert team</Typography>

                <Panel as="form" onSubmit={handleSubmit(onSubmit)} method="post" noValidate border>
                  <Spacer spacing="half" r>
                    <Typography component="h3" variant="titleMedium">
                      Your details
                    </Typography>
                    <Typography variant="smallprint">*Please complete all required form fields</Typography>

                    <Select
                      id="title"
                      label="Title"
                      {...register('title', { required: true })}
                      required
                      options={TITLES}
                      constrained
                      invalid={!!errors.title}
                      valid={touchedFields.title && dirtyFields.title && !errors.title}
                    />
                    <Input
                      type="text"
                      invalid={!!errors.firstName}
                      valid={touchedFields.firstName && dirtyFields.firstName && !errors.firstName}
                      label="First name"
                      id="firstName"
                      placeholder="e.g Sarah"
                      {...register('firstName', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      label="Last name"
                      id="lastName"
                      invalid={!!errors.lastName}
                      placeholder="e.g Smith"
                      valid={touchedFields.lastName && dirtyFields.lastName && !errors.lastName}
                      {...register('lastName', { required: true })}
                      required
                    />
                    <Input
                      type="email"
                      label="Email"
                      id="email"
                      invalid={!!errors.email}
                      valid={touchedFields.email && dirtyFields.email && !errors.email}
                      required
                      placeholder="e.g sarah@email.com"
                      {...register('email', {
                        validate: validateEmail,
                        required: true,
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <Controller
                      name="telephone"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Suspense fallback={<Loader size="small" />}>
                          <PhoneNumberInput
                            label="Phone"
                            id="telephone"
                            invalid={!!errors.telephone}
                            placeholder="Mobile or landline"
                            valid={touchedFields.telephone && dirtyFields.telephone && !errors.telephone}
                            {...field}
                          />
                        </Suspense>
                      )}
                    />
                    <Input
                      type="text"
                      invalid={!!errors.addressLine1}
                      valid={touchedFields.addressLine1 && dirtyFields.addressLine1 && !errors.addressLine1}
                      label="Address line 1"
                      id="addressLine1"
                      {...register('addressLine1', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.addressLine2}
                      valid={touchedFields.addressLine2 && dirtyFields.addressLine2 && !errors.addressLine2}
                      label="Address line 2"
                      id="addressLine2"
                      {...register('addressLine2', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.town}
                      valid={touchedFields.town && dirtyFields.town && !errors.town}
                      label="Town"
                      id="town"
                      {...register('town', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.postcode}
                      valid={touchedFields.postcode && dirtyFields.postcode && !errors.postcode}
                      label="Postcode"
                      id="postcode"
                      {...register('postcode', { required: true })}
                      required
                    />
                    <Textarea
                      invalid={!!errors.message}
                      valid={touchedFields.message && dirtyFields.message && !errors.message}
                      label="Message"
                      id="message"
                      required
                      {...register('message', { required: true })}
                    />
                  </Spacer>
                </Panel>
                <RecaptchaAction show={!isHuman} handleReCaptchaVerify={handleReCaptchaVerify} />
                <SmallPrint />
              </Spacer>
            </BladeBody>
            <BladeFooter
              primaryButton={{
                label: 'Submit',
                onClick: handleSubmit(onSubmit),
                disableClickTracking: true,
              }}
            />
          </BladeContainer>
        )}

        {isSubmitted && hasSent && (
          <BladeContainer>
            <BladeBody>
              <Panel border>
                <Spacer>
                  <StyledTitleWrap>
                    <TickCircle size="extraLarge" />
                    <Typography variant="displaySmall">Received</Typography>
                  </StyledTitleWrap>
                  <StyledLead variant="lead">Thank you for your submission.</StyledLead>
                </Spacer>
              </Panel>
            </BladeBody>
          </BladeContainer>
        )}
      </FormProvider>
    </Blade>
  );
};
