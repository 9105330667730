import React, { FC, Suspense, useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { Input } from './Input';
import { Panel } from '../Panel';
import { Spacer } from '../Spacer';
import { FormSubmissionInput } from '../../types/graphqlTypes';
import { useCreate_Corporate_Relocation_QuoteMutation } from '../../types/graphqlHelpers';
import { Textarea } from './Textarea';
import { Typography } from '../Typography';
import { Select } from './Select';
import { TITLES } from '../../const';
import Blade, { BladeBody, BladeContainer, BladeFooter } from '../Blade';

import { useDataLayerPush } from '../../hooks/useDataLayerPush';
import { SmallPrint } from './SmallPrint';
import { getFormFields } from '../../utils/getFormFields';
import { TickCircle } from '../icon/icons/TickCircle';
import { isBrowser } from '../../utils/isBrowser';
import { Loader } from '../Loader';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { RecaptchaAction } from './RecaptchaAction';
import { WhatsAppButton } from '../WhatsAppButton';
import { alertBadEmail, validateEmail } from '../../utils/emailBlackList';

const PhoneNumberInput = React.lazy(() => import('./PhoneNumberInput'));

export interface CorporateRelocationServicesProps {}

const StyledTitleWrap = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.md};
  `}
`;

const StyledLead = styled(Typography)`
  ${({ theme: { space } }) => css`
    max-width: 530px;
  `}
`;

export const CorporateRelocationServices: FC<CorporateRelocationServicesProps> = ({ ...rest }) => {
  const { dataLayerPush } = useDataLayerPush();
  // Handle form submission

  // Temp var to allow mock submisisons
  const [hasSent, setHasSent] = useState(false);

  // Mutation to submit the form
  const [CreateCorporateRelocationServicesMutation, { data, loading, error }] =
    useCreate_Corporate_Relocation_QuoteMutation();

  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const { register, handleSubmit, formState, control } = formMethods;
  const { errors, dirtyFields, touchedFields, isSubmitted } = formState;
  const { handleReCaptchaVerify, isHuman } = useRecaptcha({ formMethods, action: 'corporateRelocationServices' });
  useEffect(() => {
    function checkSuccess() {
      if (!loading && data?.form?.createFormSubmission?.success) {
        setHasSent(true);
      }
    }
    checkSuccess();
  }, [data, dataLayerPush, loading]);

  // Function to handle collecting form data and  submitting the GQL mutation
  const onSubmit: SubmitHandler<FormSubmissionInput> = async (formData) => {
    alertBadEmail(formData.email);
    delete formData.captchaToken;
    const submitData = {
      pageUrl: isBrowser ? window.location.href : '',
      type: 'CORPORATE_RELOCATION_SERVICES',
      fromName: `${formData.forename} ${formData.surname}`,
      fromEmail: formData.email,
      fields: getFormFields(formData),
    };

    dataLayerPush({
      event: 'formSubmit',
      formName: 'corporateRelocationServices',
      formValidation: 'success',
      formError: undefined,
    });

    await CreateCorporateRelocationServicesMutation({
      variables: { formSubmission: submitData },
    });
  };

  // Get CorporateRelocationServices division data
  const { corporateRelocationServicesDivisionData } = useStaticQuery(graphql`
    query corporateRelocationServicesDivisionQuery {
      corporateRelocationServicesDivisionData: kfhDivisionType(remoteId: { eq: "1009" }) {
        whatsApp {
          whatsAppEnabled
          whatsAppFullUrl
        }
      }
    }
  `);

  return (
    <Blade id="corporateRelocationServices" customBlade>
      <FormProvider {...formMethods}>
        {(!isSubmitted || !hasSent) && (
          <BladeContainer>
            <BladeBody>
              <Spacer>
                <Typography variant="displaySmall">Contact our corporate and relocation services team</Typography>

                {corporateRelocationServicesDivisionData?.whatsApp?.whatsAppEnabled && (
                  <WhatsAppButton url={corporateRelocationServicesDivisionData.whatsApp.whatsAppFullUrl} />
                )}

                <Panel as="form" onSubmit={handleSubmit(onSubmit)} method="post" noValidate border>
                  <Spacer spacing="half">
                    <Typography component="h3" variant="titleMedium">
                      Your details
                    </Typography>
                    <Typography variant="smallprint">*Please complete all required form fields</Typography>

                    <Select
                      id="title"
                      label="Title"
                      {...register('title', { required: true })}
                      required
                      options={TITLES}
                      constrained
                      invalid={!!errors.title}
                      valid={touchedFields.title && dirtyFields.title && !errors.title}
                    />
                    <Input
                      type="text"
                      invalid={!!errors.forename}
                      valid={touchedFields.forename && dirtyFields.forename && !errors.forename}
                      label="First name"
                      id="forename"
                      placeholder="e.g Sarah"
                      {...register('forename', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      label="Last name"
                      id="surname"
                      invalid={!!errors.surname}
                      placeholder="e.g Smith"
                      valid={touchedFields.surname && dirtyFields.surname && !errors.surname}
                      {...register('surname', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.employerName}
                      valid={touchedFields.employerName && dirtyFields.employerName && !errors.employerName}
                      label="Employer name"
                      id="employerName"
                      {...register('employerName', { required: true })}
                      required
                    />
                    <Input
                      type="email"
                      label="Email"
                      id="email"
                      invalid={!!errors.email}
                      valid={touchedFields.email && dirtyFields.email && !errors.email}
                      required
                      placeholder="e.g sarah@email.com"
                      {...register('email', {
                        validate: validateEmail, // email spam check
                        required: true,
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <Controller
                      name="contactNumber"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Suspense fallback={<Loader size="small" />}>
                          <PhoneNumberInput
                            label="Phone"
                            id="PhoneNumber"
                            invalid={!!errors.contactNumber}
                            placeholder="Mobile or landline"
                            required
                            valid={touchedFields.contactNumber && dirtyFields.contactNumber && !errors.contactNumber}
                            {...field}
                          />
                        </Suspense>
                      )}
                    />
                    <Input
                      type="text"
                      invalid={!!errors.desiredTerm}
                      valid={touchedFields.desiredTerm && dirtyFields.desiredTerm && !errors.desiredTerm}
                      label="Desired term"
                      id="desiredTerm"
                      {...register('desiredTerm', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.numberOfOccupants}
                      valid={
                        touchedFields.numberOfOccupants && dirtyFields.numberOfOccupants && !errors.numberOfOccupants
                      }
                      label="Number of occupants"
                      id="numberOfOccupants"
                      {...register('numberOfOccupants', { required: true })}
                      required
                    />
                    <Textarea
                      invalid={!!errors.propertyManagementPreferences}
                      valid={
                        touchedFields.propertyManagementPreferences &&
                        dirtyFields.propertyManagementPreferences &&
                        !errors.propertyManagementPreferences
                      }
                      label="Property management preferences"
                      id="propertyManagementPreferences"
                      {...register('propertyManagementPreferences', { required: true })}
                      required
                    />
                  </Spacer>
                </Panel>
                <RecaptchaAction show={!isHuman} handleReCaptchaVerify={handleReCaptchaVerify} />
                <SmallPrint />
              </Spacer>
            </BladeBody>
            <BladeFooter
              primaryButton={{
                label: 'Send enquiry',
                onClick: handleSubmit(onSubmit),
                disableClickTracking: true,
              }}
            />
          </BladeContainer>
        )}

        {isSubmitted && hasSent && (
          <BladeContainer>
            <BladeBody>
              <Panel>
                <Spacer>
                  <StyledTitleWrap>
                    <TickCircle size="extraLarge" />
                    <Typography variant="displaySmall">Received</Typography>
                  </StyledTitleWrap>
                  <StyledLead variant="lead">Thank you for your submission.</StyledLead>
                </Spacer>
              </Panel>
            </BladeBody>
          </BladeContainer>
        )}
      </FormProvider>
    </Blade>
  );
};
