import React, { FC, Suspense, useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Input } from './Input';
import { Panel } from '../Panel';
import { Spacer } from '../Spacer';
import { FormSubmissionInput } from '../../types/graphqlTypes';
import { useCreate_SurveyingMutation } from '../../types/graphqlHelpers';
import { Textarea } from './Textarea';
import { Typography } from '../Typography';
import { Select } from './Select';
import { TITLES } from '../../const';
import Blade, { BladeBody, BladeContainer, BladeFooter } from '../Blade';
import { useDataLayerPush } from '../../hooks/useDataLayerPush';
import { SmallPrint } from './SmallPrint';
import { isBrowser } from '../../utils/isBrowser';
import { Loader } from '../Loader';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { RecaptchaAction } from './RecaptchaAction';
import { TickCircle } from '../icon';
import { alertBadEmail, validateEmail } from '../../utils/emailBlackList';

const PhoneNumberInput = React.lazy(() => import('./PhoneNumberInput'));

export interface SurveyingFormProps {}

const StyledTitleWrap = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.md};
  `}
`;

const StyledLead = styled(Typography)`
  ${({ theme: { space } }) => css`
    max-width: 530px;
  `}
`;

export const SURVEY_TYPES = [
  { name: 'Homebuyer report', value: 'Homebuyer report' },
  { name: 'Building survey', value: 'Building survey' },
];

export const SurveyingForm: FC<SurveyingFormProps> = ({ ...rest }) => {
  const { dataLayerPush } = useDataLayerPush();
  const [hasSent, setHasSent] = useState(false);
  const [CreateSurveyingMutation, { data, loading }] = useCreate_SurveyingMutation();

  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const { register, handleSubmit, formState, control } = formMethods;
  const { errors, dirtyFields, touchedFields, isSubmitted } = formState;
  const { handleReCaptchaVerify, isHuman } = useRecaptcha({ formMethods, action: 'surveyingForm' });

  useEffect(() => {
    function checkSuccess() {
      if (!loading && data?.form?.createFormSubmission?.success) {
        setHasSent(true);
      }
    }
    checkSuccess();
  }, [data, dataLayerPush, loading]);

  const onSubmit: SubmitHandler<FormSubmissionInput> = async (formData) => {
    alertBadEmail(formData.email);
    delete formData.captchaToken;
    const submitData = {
      type: 'SURVEYING',
      pageUrl: isBrowser ? window.location.href : '',
      fromName: `${formData.firstName} ${formData.lastName}`,
      fromEmail: formData.email,
      fields: [
        { name: 'Title', value: formData.title },
        { name: 'First name', value: formData.firstName },
        { name: 'Last name', value: formData.lastName },
        { name: 'Email', value: formData.email },
        { name: 'Phone', value: formData.telephone },
        { name: 'Current address', value: formData.currentAddress },
        { name: 'Current Postcode', value: formData.currentPostcode },
        { name: 'Address of property being purchased', value: formData.purchaseAddress },
        { name: 'Postcode of property being purchased', value: formData.purchasePostcode },
        { name: 'Agreed purchase price', value: formData.purchasePrice },
        { name: 'Type of survey report', value: formData.surveyType },
        { name: 'Additional information', value: formData.additionalInfo },
      ],
    };

    dataLayerPush({
      event: 'formSubmit',
      formName: 'surveyingForm',
      formValidation: 'success',
      formError: undefined,
    });

    await CreateSurveyingMutation({
      variables: { formSubmission: submitData },
    });
  };

  return (
    <Blade id="surveying" customBlade>
      <FormProvider {...formMethods}>
        {(!isSubmitted || !hasSent) && (
          <BladeContainer>
            <BladeBody>
              <Spacer>
                <Typography variant="displaySmall">Request a quote</Typography>

                <Panel as="form" onSubmit={handleSubmit(onSubmit)} method="post" noValidate border>
                  <Spacer spacing="half">
                    <Typography component="h3" variant="titleMedium">
                      Your details
                    </Typography>
                    <Typography variant="smallprint">*Please complete all required form fields</Typography>

                    <Select
                      id="title"
                      label="Title"
                      {...register('title', { required: true })}
                      required
                      options={TITLES}
                      constrained
                      invalid={!!errors.title}
                      valid={touchedFields.title && dirtyFields.title && !errors.title}
                    />
                    <Input
                      type="text"
                      invalid={!!errors.firstName}
                      valid={touchedFields.firstName && dirtyFields.firstName && !errors.firstName}
                      label="First name"
                      id="firstName"
                      placeholder="e.g Sarah"
                      {...register('firstName', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      label="Last name"
                      id="lastName"
                      invalid={!!errors.lastName}
                      placeholder="e.g Smith"
                      valid={touchedFields.lastName && dirtyFields.lastName && !errors.lastName}
                      {...register('lastName', { required: true })}
                      required
                    />
                    <Input
                      type="email"
                      label="Email"
                      id="email"
                      invalid={!!errors.email}
                      valid={touchedFields.email && dirtyFields.email && !errors.email}
                      required
                      placeholder="e.g sarah@email.com"
                      {...register('email', {
                        validate: validateEmail,
                        required: true,
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <Controller
                      name="telephone"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Suspense fallback={<Loader size="small" />}>
                          <PhoneNumberInput
                            label="Phone"
                            id="telephone"
                            invalid={!!errors.telephone}
                            placeholder="Mobile or landline"
                            valid={touchedFields.telephone && dirtyFields.telephone && !errors.telephone}
                            {...field}
                          />
                        </Suspense>
                      )}
                    />
                    <Input
                      type="text"
                      invalid={!!errors.currentAddress}
                      valid={touchedFields.currentAddress && dirtyFields.currentAddress && !errors.currentAddress}
                      label="Current address"
                      id="currentAddress"
                      {...register('currentAddress', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.currentPostcode}
                      valid={touchedFields.currentPostcode && dirtyFields.currentPostcode && !errors.currentPostcode}
                      label="Current postcode"
                      id="currentPostcode"
                      {...register('currentPostcode', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.purchaseAddress}
                      valid={touchedFields.purchaseAddress && dirtyFields.purchaseAddress && !errors.purchaseAddress}
                      label="Address of property being purchased"
                      id="purchaseAddress"
                      {...register('purchaseAddress', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.purchasePostcode}
                      valid={touchedFields.purchasePostcode && dirtyFields.purchasePostcode && !errors.purchasePostcode}
                      label="Postcode of property being purchased"
                      id="purchasePostcode"
                      {...register('purchasePostcode', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      invalid={!!errors.purchasePrice}
                      valid={touchedFields.purchasePrice && dirtyFields.purchasePrice && !errors.purchasePrice}
                      label="Agreed purchase price"
                      id="purchasePrice"
                      {...register('purchasePrice', { required: true })}
                      required
                    />
                    <Select
                      id="surveyType"
                      label="Type of survey report"
                      {...register('surveyType', { required: true })}
                      required
                      options={SURVEY_TYPES}
                      invalid={!!errors.surveyType}
                      valid={touchedFields.surveyType && dirtyFields.surveyType && !errors.surveyType}
                    />
                    <Textarea
                      invalid={!!errors.additionalInfo}
                      valid={touchedFields.additionalInfo && dirtyFields.additionalInfo && !errors.additionalInfo}
                      label="Additional information"
                      id="additionalInfo"
                      {...register('additionalInfo')}
                    />
                  </Spacer>
                </Panel>
                <RecaptchaAction show={!isHuman} handleReCaptchaVerify={handleReCaptchaVerify} />
                <SmallPrint />
              </Spacer>
            </BladeBody>
            <BladeFooter
              primaryButton={{
                label: 'Submit',
                onClick: handleSubmit(onSubmit),
                disableClickTracking: true,
              }}
            />
          </BladeContainer>
        )}

        {isSubmitted && hasSent && (
          <BladeContainer>
            <BladeBody>
              <Panel border>
                <Spacer>
                  <StyledTitleWrap>
                    <TickCircle size="extraLarge" />
                    <Typography variant="displaySmall">Received</Typography>
                  </StyledTitleWrap>
                  <StyledLead variant="lead">Thank you for your submission.</StyledLead>
                </Spacer>
              </Panel>
            </BladeBody>
          </BladeContainer>
        )}
      </FormProvider>
    </Blade>
  );
};
