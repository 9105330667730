import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useContext, useEffect, useState } from 'react';
import { UiStateContext } from '../context/UiStateContext';
import { useDataLayerPush } from '../hooks/useDataLayerPush';
import { useClientDetailsLazyQuery } from '../types/graphqlHelpers';
import { getCleanPropertyDetails } from '../utils/getCleanPropertyDetails';
import { Kfh_PropertyTypeInterface } from '../types/graphqlTypes';
import { isPropertyPageCheck } from '../utils';

export interface DataLayerWrapperProps {
  children: any;
  data: any;
  serverData: any;
  location: any;
}

export const DataLayerWrapper: FC<DataLayerWrapperProps> = ({ children, data, serverData, location }) => {
  const { pathname } = location;
  const { dataLayerPush } = useDataLayerPush();
  const [clientIp, setClientIp] = useState(undefined);

  // Get internal IPS

  const {
    kfhKfhSettingsType: { internalIps },
  } = useStaticQuery(graphql`
    query internalIpsQuery {
      kfhKfhSettingsType {
        internalIps: internalCookieStateIps
      }
    }
  `);

  // Get clients IP
  const [getClientDetails, { data: clientDetails }] = useClientDetailsLazyQuery();

  useEffect(() => {
    setClientIp(clientDetails?.client.clientDetails?.ipAddress);
  }, [clientDetails]);

  useEffect(() => {
    getClientDetails();
  }, []);

  // Handle page classification

  let targetAudience;
  let targetService;

  if (data?.page && data?.page?.classification) {
    const { audiences, services } = data.page.classification;
    if (audiences && audiences.length > 0) {
      const audienceArray = audiences.map((item) => item);
      targetAudience = audienceArray.join(',');
    }
    if (services && services.length > 0) {
      const servicesArray = services.map((item) => item);
      targetService = servicesArray.join(',');
    }
  }

  // Handle Property details

  let propertyDetails: Kfh_PropertyTypeInterface;

  if (
    data?.property ||
    location?.pathname?.includes('/properties-for-sale/') ||
    location?.pathname?.includes('/commercial-properties') ||
    location?.pathname?.includes('/development-properties') ||
    location?.pathname?.includes('/search-results') ||
    location?.pathname?.includes('/properties-to-rent/')
  ) {
    propertyDetails = data?.property;
  }

  // Content group

  let contentGroup;
  if (
    location?.pathname?.includes('/properties-for-sale/') ||
    location?.pathname?.includes('/search-results') ||
    location?.pathname?.includes('/properties-to-rent/')
  ) {
    contentGroup = 'residential search results';
  }

  if (location?.pathname?.includes('/commercial-properties')) {
    contentGroup = 'commercial properties search results';
  }

  if (location?.pathname?.includes('/development-properties')) {
    contentGroup = 'development properties search results';
  }

  if (location?.pathname?.includes('/new-home-properties')) {
    contentGroup = '‘new homes search results';
  }

  if (location?.pathname?.includes('/branches')) {
    contentGroup = 'branch pages';
  }

  if (location?.pathname?.includes('/my-kfh')) {
    contentGroup = 'mykfh pages';
  }

  if (data?.property) {
    contentGroup = 'property page';
  }

  // Handle route change

  useEffect(() => {
    setTimeout(() => {
      if (clientIp) {
        // Check if page is a 'not found' property

        const isNotFoundPropertyPage = isPropertyPageCheck(pathname as string) && !data?.property;

        if (!isNotFoundPropertyPage) {
          dataLayerPush({
            event: 'gatsby-route-change',
            contentGroup,
            targetAudience,
            targetService,
            propertyDetails: getCleanPropertyDetails(propertyDetails),
            internalTraffic: internalIps.includes(clientIp),
          });
        }
      }
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, clientIp]);

  // Handle blades opening / closing

  const { activeBlade } = useContext(UiStateContext);
  const [lastBladeOpened, setLastBladeOpened] = useState();

  useEffect(() => {
    if (
      activeBlade &&
      activeBlade !== 'propertyCard' &&
      activeBlade !== 'propertyArrangeAViewing' &&
      activeBlade !== 'branch' &&
      activeBlade !== 'branchContact'
    ) {
      dataLayerPush({
        event: 'blade action',
        bladeEvent: 'opened',
        bladeOpen: true,
        bladeEventID: activeBlade,
        bladePropertyDetails: undefined,
        bladeBranchName: undefined,
      });
      setLastBladeOpened(activeBlade);
    } else if (lastBladeOpened) {
      dataLayerPush({
        event: 'blade action',
        bladeEvent: 'closed',
        bladeOpen: false,
        bladeEventID: lastBladeOpened,
        bladePropertyDetails: undefined,
        bladeBranchName: undefined,
      });
    }
  }, [activeBlade]);

  return <>{children}</>;
};
