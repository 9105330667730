import React, { FC, Suspense } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../Typography';
import { ErrorMessage } from './ErrorMessage';

const Tooltip = React.lazy(() => import('../Tooltip'));

export interface FormFieldProps {
  label: string;
  hideLabel?: boolean;
  htmlFor?: string;
  tooltipText?: string;
  tooltipInfoIcon?: boolean;
  required?: boolean;
  invalid?: boolean | string;
  errorMessage?: string;
  constrained?: boolean;
  hideAsterisk?: boolean;
  helpText?: string;
  labelHelper?: string;
}

interface StyledFormFieldProps {
  constrained?: boolean;
}

const StyledFormField = styled.div<StyledFormFieldProps>`
  ${({ theme: { space }, constrained }) => css`
    label {
      margin-bottom: ${space.sm};
      display: flex;
      gap: ${space.xs};
      sup {
        margin-left: ${space.xs};
      }
    }

    /* Hide native controls on number fields */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    // Constrained
    ${constrained &&
    css`
      max-width: 180px;
    `}
  `};
`;

const StyledHelpText = styled(Typography)`
  ${({ theme: { space } }) => css`
    margin-top: ${space.sm};
  `};
`;

const StyledLabelHelper = styled(Typography)`
  ${({ theme: { space } }) => css``};
`;

export const FormField: FC<React.PropsWithChildren<FormFieldProps>> = ({
  label,
  hideLabel,
  htmlFor,
  children,
  tooltipText,
  tooltipInfoIcon,
  required,
  invalid,
  errorMessage,
  constrained,
  hideAsterisk,
  helpText,
  labelHelper,
  ...rest
}) => (
  <StyledFormField constrained={constrained} {...rest}>
    <Typography variant="titleSmall" htmlFor={htmlFor} component="label" visuallyHidden={hideLabel}>
      {label}
      {required && !hideAsterisk ? <sup>*</sup> : ''}
      {labelHelper && <StyledLabelHelper variant="bodyLarge">{labelHelper}</StyledLabelHelper>}
      <Suspense>
        {tooltipText && <Tooltip text={tooltipText} tooltipIconSize="small" infoIcon={tooltipInfoIcon} />}
      </Suspense>
    </Typography>

    {children}

    {invalid && <ErrorMessage>{errorMessage || 'Required'}</ErrorMessage>}
    {helpText && <StyledHelpText variant="smallprint">{helpText}</StyledHelpText>}
  </StyledFormField>
);
